<template>
  <div class="logos-wrap">
    <t-image class="logos" :src="require('@/assets/images/logos.png')" />
  </div>
  <t-image
    :src="require('@/assets/images/theme-title.png')"
    class="theme-title"
  />
  <t-image :src="require('@/assets/images/theme-pic.png')" class="theme-pic" />
  <div class="content">
    <div class="card">
      <div
        class="card-item"
        v-for="item in introList"
        :key="item.flow_name"
        @click="changeCategory"
      >
        <div class="card-title">
          <div class="card-title-bar">
            <text>{{ item.flow_name }}</text>
          </div>
        </div>

        <div class="rich-txt" v-html="$decodeContent(item.flow_step)"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import "@/utils/txplayer";
import { onMounted, ref } from "vue";
import { eventInfo } from "../network/API";
import tools from "../utils/tools";
// @ is an alias to /src

const introList = ref([]);
onMounted(() => {
  getInfo();
  tools.shareInit();
});

async function getInfo() {
  let res = await eventInfo();
  console.log(res);
  introList.value = res.flows;
}
</script>
<style scoped>
.content {
  margin: 15px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(30px);
  box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 15px;
}
.card-title-bar {
  background: linear-gradient(97deg, #ffd993 -48%, #ff6d00 51%);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 25px 5px;
}
</style>
